import React from "react"
import Testimonial from "./testimonial"
import InstallButton from "@components/marketing/installButton"
import Cameron from "@src/images/optimized/testimonials/cameron"
import Sarah from "@src/images/optimized/testimonials/sarah"
import Matt from "@src/images/optimized/testimonials/matt"

const Testimonials = (props) => (
  <div id="what-people-say" className="pt-20">
    <h2 className="basis mx-auto text-gray-900 font-semibold leading-snug text-3xl max-w-lg sm:text-3xl sm:max-w-2xl md:text-4xl md:max-w-3xl lg:text-4xl lg:max-w-2xl text-center">
      What people say
    </h2>
    <div className="max-w-5xl mx-auto mb-12 sm:mb-20 mt-8 px-6">
      <div className="-mx-4 text-center">
        <div className="lg:w-1/3 inline-flex px-6">
          <Testimonial
            name="Cameron Kelley"
            img={Cameron}
            text={<span>Sheetsync makes budgeting in Google Sheets <b>so convenient.</b> I wish I had found it sooner!</span>}
          />
        </div>
        <div className="lg:w-1/3 inline-flex px-6">
          <Testimonial
            name="Sarah Mason"
            img={Sarah}
            text={<span>The <b>customer support</b> is excellent. The developer has always been very <b>helpful</b>.</span>}
          />
        </div>
        <div className="lg:w-1/3 inline-flex px-6">
          <Testimonial
            name="Matt Loh"
            img={Matt}
            text={<span>The free <b>sheet templates</b> make it really easy to track my income and spending.</span>}
          />
        </div>
      </div>
    </div>
    {props.cta !== "none" &&
      <div className="max-w-md mx-auto mt-4 flex justify-center mb-4">
        <InstallButton />
      </div>
    }

  </div>

)

export default Testimonials