import * as React from "react"
import Website from "@src/components/wrappers/website"
import Nav from "@components/marketing/header"
import Hero from "@components/marketing/hero"
import Animations from "@components/marketing/animations"
import Features from "@components/marketing/features"
import HowItWorks from "@src/components/marketing/how-it-works"
import Testimonials from "@components/marketing/testimonials"
import Security from "@src/components/marketing/security"
import CTA from "@components/marketing/cta"
import Footer from "@components/marketing/footer"

const seo = {
  url: "",
  title: "Sheetsync • Automatically sync your finances with your spreadsheet",
  description: "Sheetsync makes it easy to track your personal finances in Google Sheets by automatically syncing all your financial data with your spreadsheet."
}

export default class Index extends React.Component {
  render() {
    return (
      <Website seo={seo} marketing>
        <Nav />
        <Hero />
        <Animations />
        <Features />
        <HowItWorks />
        <Testimonials />
        <Security />
        <CTA />
        <Footer />
      </Website>
    )
  }
}
