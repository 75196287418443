import * as React from "react"
import PlaidLink from "@src/images/optimized/graphics/plaid-link"
import CreateSpreadsheet from "@images/graphics/google-sheets-animation.mp4"
import Step2 from "@src/images/optimized/graphics/step-2"

const HowItWorks = () => (
    <div id="how-it-works">
        <div className="mt-8 border border-gray-100 mx-auto max-w-6xl" />
        <p className="my-20 px-6 basis mx-auto text-gray-900 font-semibold leading-snug text-2xl max-w-lg sm:text-3xl sm:max-w-2xl md:text-4xl md:max-w-3xl lg:text-4xl lg:max-w-2xl text-center">
            Combine the <span className="text-blue-700 bg-blue-100 px-1 -mx-1 rounded-lg">convenience</span> of an app with the <span className="text-blue-700 bg-blue-100 px-1 -mx-1 rounded-lg">flexibility</span> of a spreadsheet.
        </p>

        <div className="py-20 sm:py-40 bg-gray-50">
            <div className="sm:flex mx-auto max-w-5xl items-center">
                <div className="sm:flex-1 px-12">
                    <div className="sm:mx-auto sm:max-w-md">
                        <div className="bg-blue-100 rounded-xl inline-flex mx-auto">
                            <div className="h-12 w-12 text-white rounded-xl p-3 bg-blue-500 inline-block">
                                <svg fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M9 12l2 2 4-4m5.618-4.016A11.955 11.955 0 0112 2.944a11.955 11.955 0 01-8.618 3.04A12.02 12.02 0 003 9c0 5.591 3.824 10.29 9 11.622 5.176-1.332 9-6.03 9-11.622 0-1.042-.133-2.052-.382-3.016z" />
                                </svg>
                            </div>
                            <p className="basis font-semibold text-base sm:text-lg bg-blue-100 rounded-lg inline-block text-blue-600 px-6 py-2.5">Step 1</p>
                        </div>
                        <p className="basis text-2xl sm:text-3xl md:text-4xl leading-tight font-semibold text-gray-900 max-w-xs my-8">Link your financial accounts.</p>
                        <ul className="max-w-sm">
                            <li className="flex items-start">
                                <div className="flex-shrink-0">
                                    <svg className="h-6 w-6 md:h-7 md:w-7 text-blue-500" stroke="currentColor" fill="none" viewBox="0 0 24 24">
                                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="3" d="M5 13l4 4L19 7" />
                                    </svg>
                                </div>
                                <p className="md:mt-0.5 ml-3 text-base md:text-lg leading-6 text-gray-700">
                                    Securely link with 12,000+ financial institutions worldwide using Plaid.
                    		    </p>
                            </li>
                            <li className="mt-4 flex items-start">
                                <div className="flex-shrink-0">
                                    <svg className="h-6 w-6 md:h-7 md:w-7 text-blue-500" stroke="currentColor" fill="none" viewBox="0 0 24 24">
                                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="3" d="M5 13l4 4L19 7" />
                                    </svg>
                                </div>
                                <p className="md:mt-0.5 ml-3 text-base md:text-lg leading-6 text-gray-700">
                                    Connect bank accounts, credit cards, investments, loans, and more.
                                </p>
                            </li>
                            <li className="mt-4 flex items-start">
                                <div className="flex-shrink-0">
                                    <svg className="h-6 w-6 md:h-7 md:w-7 text-blue-500" stroke="currentColor" fill="none" viewBox="0 0 24 24">
                                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="3" d="M5 13l4 4L19 7" />
                                    </svg>
                                </div>
                                <p className="md:mt-0.5 ml-3 text-base md:text-lg leading-6 text-gray-700">
                                    Receive your historical transaction data immediately upon linking.
                                </p>
                            </li>
                        </ul>
                    </div>
                </div>
                <div className="mt-12 sm:flex-1 sm:mt-0 px-12">
                    <div className="mx-auto max-w-xs px-2">
                        <div className="shadow-2xl-dark rounded-lg">
                            <PlaidLink />
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div className="py-20 sm:py-40">
            <div className="sm:flex mx-auto max-w-5xl items-center">
                <div className="hidden sm:flex sm:flex-1 sm:pl-6 sm:pr-6">
                    <div className="mx-auto max-w-lg w-full">
                        <div className="shadow-2xl rounded-xl overflow-hidden">
                            <video autoPlay loop muted playsInline className="w-full relative z-10">
                                <source src={CreateSpreadsheet} type="video/mp4" />
                            </video>
                        </div>
                    </div>
                </div>
                <div className="sm:flex-1 px-12">
                    <div className="bg-blue-100 rounded-xl inline-flex">
                        <div className="h-12 w-12 text-white rounded-xl p-3 bg-blue-500 inline-block">
                            <svg fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 4v5h.582m15.356 2A8.001 8.001 0 004.582 9m0 0H9m11 11v-5h-.581m0 0a8.003 8.003 0 01-15.357-2m15.357 2H15" />
                            </svg>
                        </div>
                        <p className="basis font-semibold text-base sm:text-lg bg-blue-100 rounded-lg inline-block text-blue-600 px-6 py-2.5">Step 2</p>
                    </div>
                    <p className="basis text-2xl sm:text-3xl md:text-4xl leading-tight font-semibold text-gray-900 max-w-md my-8">Receive automatic spending and balance updates.</p>
                    <ul className="max-w-md">
                        <li className="flex items-start">
                            <div className="flex-shrink-0">
                                <svg className="h-6 w-6 md:h-7 md:w-7 text-blue-500" stroke="currentColor" fill="none" viewBox="0 0 24 24">
                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="3" d="M5 13l4 4L19 7" />
                                </svg>
                            </div>
                            <p className="md:mt-0.5 ml-3 text-base md:text-lg leading-6 text-gray-700">
                                Receive multiple updates every day.
                    		</p>
                        </li>
                        <li className="mt-4 flex items-start">
                            <div className="flex-shrink-0">
                                <svg className="h-6 w-6 md:h-7 md:w-7 text-blue-500" stroke="currentColor" fill="none" viewBox="0 0 24 24">
                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="3" d="M5 13l4 4L19 7" />
                                </svg>
                            </div>
                            <p className="md:mt-0.5 ml-3 text-base md:text-lg leading-6 text-gray-700">
                                Never waste time manually logging your transactions or financial history.
                            </p>
                        </li>
                    </ul>
                </div>
                <div className="mt-12 sm:hidden px-12">
                    <div className="mx-auto">
                        <div className="shadow-2xl rounded-xl overflow-hidden">
                            <video autoPlay loop muted playsInline className="w-full relative z-10">
                                <source src={CreateSpreadsheet} type="video/mp4" />
                            </video>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div className="py-20 sm:py-40 bg-gray-50">
            <div className="sm:flex mx-auto max-w-5xl items-center">
                <div className="sm:flex-1 px-12 sm:pr-6">
                    <div className="bg-blue-100 rounded-xl inline-flex">
                        <div className="h-12 w-12 text-white rounded-xl p-3 bg-blue-500 inline-block">
                            <svg fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M15 15l-2 5L9 9l11 4-5 2zm0 0l5 5M7.188 2.239l.777 2.897M5.136 7.965l-2.898-.777M13.95 4.05l-2.122 2.122m-5.657 5.656l-2.12 2.122" />
                            </svg>
                        </div>
                        <p className="basis font-semibold text-base sm:text-lg bg-blue-100 rounded-lg inline-block text-blue-600 px-6 py-2.5">Step 3</p>
                    </div>
                    <p className="basis text-2xl sm:text-3xl md:text-4xl leading-tight font-semibold text-gray-900 max-w-sm my-8">Customize your spreadsheet.</p>
                    <ul className="max-w-sm">
                        <li className="flex items-start">
                            <div className="flex-shrink-0">
                                <svg className="h-6 w-6 md:h-7 md:w-7 text-blue-500" stroke="currentColor" fill="none" viewBox="0 0 24 24">
                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="3" d="M5 13l4 4L19 7" />
                                </svg>
                            </div>
                            <p className="md:mt-0.5 ml-3 text-base md:text-lg leading-6 text-gray-700">
                                Use premade sheet templates to set your budget, analyze spending, or track your net worth.
                            </p>
                        </li>
                        <li className="mt-4 flex items-start">
                            <div className="flex-shrink-0">
                                <svg className="h-6 w-6 md:h-7 md:w-7 text-blue-500" stroke="currentColor" fill="none" viewBox="0 0 24 24">
                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="3" d="M5 13l4 4L19 7" />
                                </svg>
                            </div>
                            <p className="md:mt-0.5 ml-3 text-base md:text-lg leading-6 text-gray-700">
                                Customize your spreadsheet as much or as little as you like - it's yours.
                            </p>
                        </li>
                    </ul>
                </div>
                <div className="mt-12 sm:flex-1 sm:mt-0 px-12 sm:px-6">
                    <div className="mx-auto max-w-lg">
                        <div className="shadow-2xl rounded-xl overflow-hidden">
                            <div className="w-full relative z-10">
                                <Step2 />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    </div>
)

export default HowItWorks
