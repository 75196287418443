import React from "react"
import InstallButton from "@components/marketing/installButton"

export default () => (
	<div className="text-center px-6 py-10 sm:py-12 md:py-16">
		<h1 className="mx-auto text-gray-900 font-semibold leading-tighter text-3xl sm:text-5xl md:text-55xl lg:text-55xl">
			<span className="text-blue-700">Easy Money Management</span><br /> in Google Sheets.
    	</h1>
		<p className="text-gray-700 mx-auto mt-3 md:mt-6 text-md max-w-lg sm:text-lg sm:max-w-lg md:text-xl md:max-w-2xl">
			Sheetsync automatically updates your spreadsheet with your finances so you can easily track your entire financial life in Google Sheets.
    	</p>

		<div className="flex justify-center mt-5 md:mt-8">
			<InstallButton />
		</div>
	</div>
)
