import * as React from "react"
import InstallButton from "@src/components/marketing/installButton"

const Features = () => (
    <div>
        <p className="mt-10 mb-12 px-6 basis mx-auto text-gray-900 font-semibold leading-snug text-2xl max-w-lg sm:text-3xl sm:max-w-2xl md:text-4xl md:max-w-5xl lg:text-4xl text-center">
            It's the best way to <span className="text-blue-700 bg-blue-100 px-1 -mx-1 rounded-lg">set a budget</span>, <span className="text-blue-700 bg-blue-100 px-1 -mx-1 rounded-lg">analyze spending</span>, or <span className="text-blue-700 bg-blue-100 px-1 -mx-1 rounded-lg">track your net worth</span> in Google Sheets.
        </p>
        <div className="py-12 bg-white">
            <div className="max-w-xl mx-auto px-6 lg:max-w-4xl">
                <div className="lg:grid lg:grid-cols-3 lg:gap-16">
                    <div>
                        <div className="flex items-center justify-center h-12 w-12 rounded-md bg-blue-500 text-white">
                            <svg className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 4v5h.582m15.356 2A8.001 8.001 0 004.582 9m0 0H9m11 11v-5h-.581m0 0a8.003 8.003 0 01-15.357-2m15.357 2H15" />
                            </svg>
                        </div>
                        <div className="mt-5">
                            <h5 className="text-xl leading-6 font-medium text-gray-900">
                                Automatic updates
                            </h5>
                            <p className="mt-2 text-base leading-6 text-gray-500">
                                Say goodbye to CSV exports and manual data entry - Sheetsync does it all for you.
                            </p>
                        </div>
                    </div>
                    <div className="mt-10 lg:mt-0">
                        <div className="flex items-center justify-center h-12 w-12 rounded-md bg-blue-500 text-white">
                            <svg className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M16 8v8m-4-5v5m-4-2v2m-2 4h12a2 2 0 002-2V6a2 2 0 00-2-2H6a2 2 0 00-2 2v12a2 2 0 002 2z" />
                            </svg>
                        </div>
                        <div className="mt-5">
                            <h5 className="text-xl leading-6 font-medium text-gray-900">
                                Powerful templates
                            </h5>
                            <p className="mt-2 text-base leading-6 text-gray-500">
                                Start with a robust spreadsheet template for built-in insights and analysis.
                            </p>
                        </div>
                    </div>
                    <div className="mt-10 lg:mt-0">
                        <div className="flex items-center justify-center h-12 w-12 rounded-md bg-blue-500 text-white">
                            <svg className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M15.232 5.232l3.536 3.536m-2.036-5.036a2.5 2.5 0 113.536 3.536L6.5 21.036H3v-3.572L16.732 3.732z" />
                            </svg>
                        </div>
                        <div className="mt-5">
                            <h5 className="text-xl leading-6 font-medium text-gray-900">Fully customizable</h5>
                            <p className="mt-2 text-base leading-6 text-gray-500">
                                Customize your spreadsheet as much or as little as you want. It's yours.
                            </p>
                        </div>
                    </div>
                </div>
                <div className="max-w-md mx-auto mt-16 flex justify-center mb-4">
                    <InstallButton />
                </div>
            </div>
        </div>
    </div>
)

export default Features