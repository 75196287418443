import * as React from "react"
import Stars from "@images/icons/five-stars.svg"

const Testimonial = (props) => (

    <div className="max-w-3xl bg-gray-100 shadow-lg mx-auto mt-20 py-8 px-6 rounded-lg text-center">
        <div className="-mt-16 h-16 w-16 mx-auto rounded-full overflow-hidden">
            <props.img/>
        </div>
        <img src={Stars} className="h-4 mx-auto my-4" />
        <p className="text-gray-700 mb-2 text-sm sm:text-base">
            "{props.text}"
		</p>
        <p className="font-semibold mt-4 basis text-lg text-gray-700">- {props.name}</p>
    </div>

)

export default Testimonial