import React from "react"
import { Link } from "gatsby"

const Security = () => (
    <div className="text-center px-6">
        <div className="mt-10 sm:mt-16 md:mt-20 lg:mt-24 border border-gray-100 mx-auto max-w-6xl"/>
        <h2 className="text-xl sm:text-3xl md:text-4xl leading-normal font-bold text-gray-900 max-w-4xl mx-auto mt-12 sm:mt-16 md:mt-20 text-center">
            Private and Secure.
        </h2>
        <p className="text-base sm:text-lg leading-normal text-gray-700 max-w-3xl mx-auto mt-2 mb-5 text-center">
            Sheetsync never has access to your bank credentials and never sells your data. We're committed to respecting your privacy, and we take that responsibility very seriously.<br/>
        </p>
        <Link to="/privacy-and-security" className="mb-8 text-base sm:text-lg text-blue-700 px-6 py-3 rounded-md font-medium hover:bg-blue-50 hover:text-blue-700">Find out more →</Link>
        <div className="mt-12 sm:mt-16 md:mt-20 border border-gray-100 mx-auto max-w-6xl"/>
    </div>
)

export default Security