import * as React from "react"
import LogoRow1 from "@images/graphics/hero-logo-row-1.png"
import LogoRow2 from "@images/graphics/hero-logo-row-2.png"
import LogoRow3 from "@images/graphics/hero-logo-row-3.png"
import Link from "@images/graphics/link-line.svg"
import SpreadsheetAnimation from "@images/graphics/google-sheets-animation.mp4"

const Animation = () => (
    <div className="text-center overflow-hidden">
        <div className="mt-16 mb-2 overflow-hidden inline-block mx-auto relative animation-big-container">
            <div className="block absolute h-full w-20 gradient-left z-10" />
            <div className="block absolute h-full w-20 gradient-right z-10 right-0" />
            <div className="overflow-hidden relative h-16 sm:h-24 mx-auto animate-marquee-container-1">

                <div className="block absolute overflow-hidden animate-marquee-1 animation-1">
                    <span className="marquee-span">
                        <img src={LogoRow1} alt="Financial institution logos" />
                    </span>
                    <span className="marquee-span">
                        <img src={LogoRow1} alt="Financial institution logos" />
                    </span>
                </div>
            </div>
            <div className="sm:-mt-2 -mt-5 overflow-hidden relative h-16 sm:h-24 mx-auto animate-marquee-container-2">
                <div className="block absolute overflow-hidden animate-marquee-2 animation-2">
                    <span className="marquee-span">
                        <img src={LogoRow2} alt="Financial institution logos" />
                    </span>
                    <span className="marquee-span">
                        <img src={LogoRow2} alt="Financial institution logos" />
                    </span>
                </div>
            </div>
            <div className="sm:-mt-2 -mt-5 overflow-hidden relative h-16 sm:h-24 mx-auto animate-marquee-container-3">
                <div className="block absolute overflow-hidden animate-marquee-3 animation-3">
                    <span className="marquee-span">
                        <img src={LogoRow3} alt="Financial institution logos" />
                    </span>
                    <span className="marquee-span">
                        <img src={LogoRow3} alt="Financial institution logos" />
                    </span>
                </div>
            </div>
        </div>
        <img src={Link} alt="" className="mx-auto -mt-4 sm:mt-0 w-8 sm:w-12" />
        <p className="basis text-center my-6 px-6 mx-auto text-gray-900 font-semibold leading-tighter text-2xl max-w-lg sm:text-3xl sm:max-w-2xl md:text-4xl md:max-w-3xl lg:text-4xl lg:max-w-3xl">
            Receive <span className="text-blue-700 bg-blue-100 px-1 -mx-1 rounded-lg">automatic</span> spending and balance updates directly in your spreadsheet.
        </p>
        <img src={Link} alt="" className="mx-auto w-8 sm:w-12 upsidedown -mb-4 sm:-mb-7 relative z-20" />
        <div className="justify-center mb-16 relative px-6 sm:px-12">
            <div className="max-w-3xl mx-auto rounded-xxl overflow-hidden shadow-2xl">
                <video autoPlay loop muted playsInline className="w-full relative z-10">
                    <source src={SpreadsheetAnimation} type="video/mp4" />
                </video>
            </div>
        </div>
    </div >
)

export default Animation